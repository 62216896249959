const he = {
  ThankYou: 'תודה',
  downloadTheApp: 'הורידו את האפליקציה',
  downloadNow: 'Download now',
  forPurchasingCyberCoverage: `שרכשתם את שירות כיסוי הסייבר של ביטוח {brand}`,
  welcomeToCyberAid: 'Welcome to CyberAid’s warranty!',
  bestCyberProtection: 'CyberAid’s app — get the best cyber protection',
  downloadOurApp: 'Download our app for an all-in-one solution to your personal cyber threats, exclusively for extended warranty holders.',
  stayOneStepAheadOfHackers: 'Stay one step ahead of hackers. CyberAid uses advanced technology to identify and prevent potential cyber threats. In case of a cyber-attack, our experts will help you stop the attack and recover.',
  empowerYourDigitalSafety: 'Empower your digital safety',
  downloadBituahYashirApp: 'הורידו את אפליקציית CyberUp להגנה מרבית מתקיפות סייבר',
  assitsToProvideCyberAttack: 'CyberUp מאתרת חולשות אבטחה ברשת הביתית, מסייעת במניעת ועצירת תקיפות סייבר ומאפשרת לקבל תמיכה מקצועית במצבי חירום',
  appInstallDiscount: 'התקנת האפליקציה תקנה לכם 50% הנחה בהשתתפות עצמית במקרה של תקיפה',
  downloadAndLearnHowToProtect: 'הורידו את אפליקציית CyberUp ולמדו איך להגן על הרשת הביתית שלכם',
  allRightsRegistered: '© Surance.io. All rights registered.',
  termsOfUse: 'Terms of use',
  privacyPolicy: 'Privacy policy',
  helpCenter: 'Help center & Chatbot',
  contactUs: 'צור קשר',
  innovativeTechnology: 'Innovative technology to identify and prevent cyber threats.',
  cyberExpertResponse: '24/7 cyber expert response to stop attacks.',
  darkWebMonitoring: 'Dark web monitoring for personal data leak.',
  advancedPhishingDetection: 'Advanced phishing detection.',
  yourSecretSuperPower: 'CyberAid’s app — your secret superpower against cyber attacks',
};

module.exports = he;
