import React from 'react';
import {  StyleSheet, Image } from 'react-native';
import { useTheme } from '@suranceadmin/surance-ui-components';
import { isSmallScreen, isExtraSmallScreen } from '../../utils';
import TopBarCommon from '../common/TopBar';
import IMAGES from '../../Images';
import { useIntl } from 'react-intl';

export const getTopBarTextAndBackgroundImage = () => {
    const intl = useIntl();
    const topBarTextAndBackgroundImage = {
        title: intl.formatMessage({ id: 'empowerYourDigitalSafety' }),
        backgroundImage : IMAGES.cyberAidBackground,
    };
    return topBarTextAndBackgroundImage;
}
const TopBar = () => {
    const theme = useTheme();
    const { styles } = useStyle(theme);
    const logo = <Image style={styles.logo} source={IMAGES.suranceLogoCyberAid} />;
    const { title, backgroundImage } = getTopBarTextAndBackgroundImage();

    return (
        <TopBarCommon {...{ logo, backgroundImage, styles, title }} />
    )
};

const useStyle = (theme) => {
    const styles = StyleSheet.create({
        container: {
            minHeight: 400,
            writingDirection: 'auto',
            position: 'relative',
        },
        horizontalPadding: {
            paddingHorizontal: 24,
        },
        title: {
            marginTop: isExtraSmallScreen ? 5 : isSmallScreen ? 5 : 32,
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.text.ondark,
        },
        subTitle: {
            color: theme.colors.text.ondark,
            textAlign: theme.isRTL ? 'right' : 'left',
        },
        logoContainer: {
            alignItems: theme.isRTL ? 'flex-end' : 'flex-start',
        },
        logo: {
            width: 194,
            height: 56,
            marginTop: isExtraSmallScreen ? 10 : 11
        },
    });
    return { styles };
};

export default TopBar;