import { isSmallScreen } from "../utils";
import { getProjectId } from "../utils";
import { CYBERAID, IDI } from "../constants";
import { getFontsEnglish, getFontsEnglishSmall } from "./FontsEnglish";
import { getFontsHebrew, getFontsHebrewSmall } from "./FontsHebrew";

const Colors = {
    background: {
        white: "#FFFFFF",
        default: "#F0F0F0",
        header: "#012230",
        headerModal: "#052E40",
        important: "#F9EEE2",
        warning: "#CB3E5A",
    },
    button: {
        primary: "#01B0C1",
        secondary: "#FFFFFF",
        negative: "#CB3E5A",
        disabled: "#DDE2E8",
        secondaryBorder: "#01B0C1",
        transparentBorder: "#FFFFFF",
    },
    text: {
        default: "#052E40",
        light: "#546B84",
        ondark: "#FFFFFF",
        disabled: "#93A3B6",
        link: "#01B0C1",
        warning: "#CB3E5A",
        buttonSecondary: "#052E40",
        subtitle: "001E2B",
    },
    line: {
        default: "#93A3B6",
        light: "#DDE2E8",
    },
    level: {
        unknown: "#93A3B6",
        notSafe: "#CB3E5A",
        mildlySafe: "#DD8206",
        fullySafe: "#3E78C7",
    },
    notification: {
        critical: "#CB3E5A",
        important: "#DD8206",
        improvement: "#E6AF0B",
    },
    icon: {
        default: "#052E40",
        link: "#01B0C1",
        ondark: "#FFFFFF",
        warning: "#CB3E5A",
        critical: "#CB3E5A",
        important: "#DB7B2B",
        improvement: "#E6AF0B",
        valid: "#01B0C1",
    },
};

const FontsHebrew = getFontsHebrew();
const FontsHebrewSmall = getFontsHebrewSmall();
const FontsEnglish = getFontsEnglish();
const FontsEnglishSmall = getFontsEnglishSmall();

const fontDefinitions = {
    'Arial': require('../assets/fonts/Arial.ttf'),
    'Arial-Bold': require('../assets/fonts/Arial-Bold.ttf'),
    'MontserratAlternates-Regular': require('../assets/fonts/MontserratAlternates-Regular.ttf'),
    'MontserratAlternates-Bold': require('../assets/fonts/MontserratAlternates-Bold.ttf'),
    'PT-RootUI-Regular': require('../assets/fonts/pt-root-ui_regular.ttf'),
    'PT-RootUI-Bold': require('../assets/fonts/pt-root-ui_bold.ttf'),
};

const getFontsLanguage = () => {
    const projectId = getProjectId();
    const fontsMapping = {
        [IDI]: isSmallScreen ? FontsHebrewSmall : FontsHebrew,
        [CYBERAID]: isSmallScreen ? FontsEnglishSmall : FontsEnglish,
    };
    return fontsMapping[projectId];
}

const Theme = {
    colors: Colors,
    fonts: getFontsLanguage(),
    fontDefinitions: fontDefinitions,
};

export default Theme;
