import React, { useEffect, useState } from 'react';
import { StyleSheet, View, I18nManager } from 'react-native';
import Container from './components';
import { useFonts } from 'expo-font';
import { ThemeProvider as SuranceThemeProvider } from "@suranceadmin/surance-ui-components";
import theme from "./themes/light.theme";
import Loading from './components/Loading';
import Locale from "./locale";
import { setTabNameAndIcon } from './utils';
import { languages } from './constants';
const { IntlProvider, currentLocale } = Locale;
import { initializeAnalytics } from './Analytics';

export default function App() {
  const [desktop, setDesktop] = useState(window.innerWidth > 500);
  const { styles } = useStyle(desktop);
  const [fontsLoaded] = useFonts(theme.fontDefinitions);
  const locale = currentLocale;
  const isRTL = currentLocale === languages.HEBREW;
  I18nManager.allowRTL(isRTL);
  I18nManager.forceRTL(isRTL);

  useEffect(() => {
    setTabNameAndIcon();
    initializeAnalytics();
  }, []);

  const handleWindowSizeChange = () => {
    setDesktop(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (!fontsLoaded) {
    return <Loading />
  } else {
    return (
      <IntlProvider key={locale} locale={locale}>
        <SuranceThemeProvider theme={{ ...theme, ...{ isRTL } }}>
          <View style={styles.container}>
            <Container desktop={desktop} />
          </View>
        </SuranceThemeProvider>
      </IntlProvider>
    );
  }
}
const useStyle = (desktop) => {
  const styles = StyleSheet.create({
    container: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: 'black',
      backgroundColor: desktop ? 'white' : 'black',
      alignItems: desktop && 'center',
    },
  });
  return { styles };
}

