
import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import TopBar from './TopBar';
import Main from './Main';
import BottomBar from './BottomBar';
import IMAGES from '../../Images';

const BituahFive = () => {
    const [topBarLayout, setTopBarLayout] = useState(0);
    const { styles } = useStyle(topBarLayout);
    const umbrellaImg = <Image style={styles.umbrella} source={IMAGES.fiveUmbrella} />

    return (
        <View style={styles.container}>
            <TopBar setLayout={setTopBarLayout} />
            {umbrellaImg}
            <Main />
            <BottomBar />
        </View>
    )
}

const useStyle = (topBarLayout) => {
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#1E285A'
        },
        umbrella: {
            width: 164,
            height: 164,
            position: 'absolute',
            left: 20,
            top: topBarLayout - 125,
            zIndex: 9
        },
    });
    return { styles };
}




export default BituahFive;