export const flattenObject = (ob) => {
    const result = {};
    Object.entries(ob).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        const flatObject = flattenObject(value);
        Object.entries(flatObject).forEach(([nestedKey, nestedValue]) => {
          result[`${key}.${nestedKey}`] = nestedValue;
        });
      } else {
        result[key] = value;
      }
    });
    return result;
  };
  
  export const getParsedTranslations = (trans) => {
    return trans
      ? Object.entries(trans)
          .map(([key, value]) => {
            return [key, flattenObject(value)];
          })
          .reduce(
            (flattenedTranslations, [key, value]) => ({
              ...flattenedTranslations,
              [key]: value,
            }),
            {},
          )
      : {};
  };
  
  export const getMissingKeys = (allTranslations) => {
    const translationLanguages = Object.keys(allTranslations);
  
    const missingTranslations = [];
    translationLanguages.forEach((language) => {
      const currentTranslations = Object.keys(allTranslations[language]);
      translationLanguages.forEach((otherLanguage) => {
        if (language !== otherLanguage) {
          const otherLanguageTranslations = Object.keys(
            allTranslations[otherLanguage],
          );
          const missingInOther = currentTranslations.filter(
            (x) => !otherLanguageTranslations.includes(x),
          );
          if (missingInOther.length) {
            missingInOther.map((x) =>
              missingTranslations.push(
                `${otherLanguage} is missing translation key ${x}`,
              ),
            );
          }
        }
      });
    });
  
    return missingTranslations;
  };
  