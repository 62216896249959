import React from 'react';
import { View, StyleSheet, Image, TouchableOpacity, Linking } from 'react-native';
import { Text, TextType, useTheme } from '@suranceadmin/surance-ui-components';
import { PRIVACY_POLICY, SUPPORT_URL, TERMS_OF_USE, analyticsLabels } from '../../constants';
import IMAGES from '../../Images';
import { useIntl } from 'react-intl';
import { useAnalyticsEventTracker } from '../../Analytics';

const HelpCenter = ({ onHelpCenterPress }) => {
    const intl = useIntl();
    const theme = useTheme();
    const { styles } = useStyle(theme);
    return (
        <TouchableOpacity onPress={onHelpCenterPress} style={styles.touchableOpacity}>
            <Image style={styles.helpCenterImg} source={IMAGES.helpCenter} />
            <Text type={TextType.Caption1} style={styles.HelpCenterText}>
                {intl.formatMessage({ id: 'helpCenter' })}
            </Text>
        </TouchableOpacity>
    )
}

const TermsPolicyAndRightRegistered = ({ onTermsOfUsePress, onPrivacyPolicyPress }) => {
    const intl = useIntl();
    const theme = useTheme();
    const { styles } = useStyle(theme);
    return (
        <View style={styles.termsPolicyAndRightRegistered}>
            <Text style={{ color: theme.colors.text.disabled }} type={TextType.Caption2} >{intl.formatMessage({ id: 'allRightsRegistered' })}</Text>
            <View style={styles.termsAndPolicyContainer}>
                <Text type={TextType.Caption2} style={{ color: theme.colors.text.link }} onPress={onTermsOfUsePress}>
                    {intl.formatMessage({ id: 'termsOfUse' })}
                </Text>
                <Text type={TextType.Caption2} style={styles.privacyPolicy} onPress={onPrivacyPolicyPress}>
                    {intl.formatMessage({ id: 'privacyPolicy' })}
                </Text>
            </View>
        </View>
    )
}
export const BottomBar = () => {
    const theme = useTheme();
    const { styles } = useStyle(theme);

    const onHelpCenterPress = () => {
        const label = analyticsLabels.HELP_CENTER;
        useAnalyticsEventTracker(label);
        Linking.openURL(SUPPORT_URL);
    }

    const onTermsOfUsePress = () => {
        const label = analyticsLabels.TERMS_OF_USE;
        useAnalyticsEventTracker(label);
        Linking.openURL(TERMS_OF_USE);
    }

    const onPrivacyPolicyPress = () => {
        const label = analyticsLabels.PRIVACY_POLICY;
        useAnalyticsEventTracker(label);
        Linking.openURL(PRIVACY_POLICY);
    }


    return (
        <View style={{ backgroundColor: theme.colors.background.header }}>
            <View style={styles.borderLine} />
            <View style={styles.container} >
                <HelpCenter onHelpCenterPress={onHelpCenterPress} />
                <TermsPolicyAndRightRegistered onTermsOfUsePress={onTermsOfUsePress} onPrivacyPolicyPress={onPrivacyPolicyPress} />
            </View>
        </View>
    )
}

const useStyle = (theme) => {

    const styles = StyleSheet.create({
        container: {
            writingDirection: 'auto',
            paddingRight: 16,
            minHeight: 100,
            position: 'relative',
            paddingLeft: 20,
            backgroundColor: theme.colors.background.header
        },
        helpCenterImg: {
            width: 24,
            height: 24,
        },
        HelpCenterText: {
            color: theme.colors.text.link,
            paddingStart: 8
        },
        touchableOpacity: {
            flexDirection: 'row',
            paddingTop: 24
        },
        termsAndPolicyContainer: {
            flexDirection: 'row',
            paddingTop: 8,
            paddingBottom: 16
        },
        privacyPolicy: {
            color: theme.colors.text.link,
            paddingStart: 16
        },
        termsPolicyAndRightRegistered: {
            paddingTop: 14
        },
        borderLine: {
            borderTopWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0.3)',
            borderTopStyle: 'solid',
        },
    })
    return { styles };
}

export default BottomBar;