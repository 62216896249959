import React from 'react';
import { View, StyleSheet, ImageBackground } from 'react-native';
import { Text, TextType, useTheme } from '@suranceadmin/surance-ui-components';
import { urls, NINE_PHONE_NUMBER, FIVE_PHONE_NUMBER } from '../../constants';
import { isBituah9 } from '../../utils';
import { useIntl } from 'react-intl';
import IMAGES from '../../Images';

const getBottomDetailsByBrand = () => {
    const Bituah9 = isBituah9();
    const nineBrandDetails = {
        url: urls.NINE,
        phone: NINE_PHONE_NUMBER,
        imageBackgroundSource: IMAGES.nineBottomBarImageSrc
    };
    const fiveBrandDetails = {
        url: urls.FIVE,
        phone: FIVE_PHONE_NUMBER,
        imageBackgroundSource: IMAGES.fiveBottomBarImageSrc
    };
    return Bituah9 ? nineBrandDetails : fiveBrandDetails;
}

export const BottomBar = () => {
    const theme = useTheme();
    const intl = useIntl();
    const { styles } = useStyle(theme);
    const { url, phone, imageBackgroundSource } = getBottomDetailsByBrand();
    return (
        <ImageBackground source={imageBackgroundSource} style={styles.background}>
            <View style={styles.container}>
                <Text type={TextType.Caption2} style={styles.bottomText}>{intl.formatMessage({ id: 'contactUs' })}</Text>
                <Text type={TextType.Caption2} style={styles.bottomText}>{url}</Text>
                <Text type={TextType.Caption2} style={styles.bottomText}>{phone}</Text>
            </View>
        </ImageBackground>
    )
}

const useStyle = (theme) => {

    const styles = StyleSheet.create({
        background: {
            writingDirection: 'auto',
            paddingRight: 16,
            minHeight: 100,
            position: 'relative',
            paddingLeft: 20
        },
        container: {
            paddingTop: 26,
            paddingBottom: 10
        },
        bottomText: {
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.text.ondark
        },
    })
    return { styles };
}

export default BottomBar;