import React from 'react';
import BituahNine from './BituahNine';
import BituahFive from './BituahFive';
import { isBituah9 } from '../../utils';

export const BituahYashir = () => {
    const Bituah9 = isBituah9();
    return (
        <>
            {Bituah9 ?
                <BituahNine />
                :
                <BituahFive />
            }
        </>
    )
}

export default BituahYashir;