
export const getFontsEnglishSmall = () => {
    const FontsEnglishSmall = {
        headline1: {
            fontFamily: 'MontserratAlternates-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 38,
            lineHeight: 40,
        },
        headline2: {
            fontFamily: 'MontserratAlternates-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 24,
            lineHeight: 28,
        },
        subtitle: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 20,
        },
        subtitleBold: {
            fontFamily: 'PT-RootUI-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 18,
            lineHeight: 20,
        },
        body: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 24,
        },
        bodyBold: {
            fontFamily: 'Montserrat-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 24,
        },
        bodyWide: {
            fontFamily: "PTSans-Regular",
            fontSize: 14,
            lineHeight: 21,
        },
        buttonPrimary: {
            fontFamily: 'PT-RootUI-Bold',
            fontweight: '700',
            fontSize: 14,
            lineHeight: 16,
            textTransform: "uppercase",
            letterSpacing: 1,
            marginBottom: -3.5,
            marginTop: -0.5,
            paddingBottom: 2,
            alignSelf: 'stretch',
        },
        buttonSecondary: {
            fontFamily: "PTSansNarrow-Regular",
            fontSize: 12,
            lineHeight: 12,
        },
        overline: {
            fontFamily: "PTSans-Regular",
            fontSize: 12,
            lineHeight: 12,
            textTransform: "uppercase",
        },
        overlineBold: {
            fontFamily: "PTSans-Bold",
            fontSize: 12,
            lineHeight: 12,
            textTransform: "uppercase",
        },
        caption1: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 23,
        },
        caption1Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 12,
            lineHeight: 15,
        },
        caption2: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '40',
            fontSize: 12,
            lineHeight: 16,
        },
        caption2Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 14,
            lineHeight: 16,
        },
        caption3: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 18,
        },
        caption3Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 9,
            lineHeight: 12,
        },
    };
    return FontsEnglishSmall;
}

export const getFontsEnglish = () => {
    const FontsEnglish = {
        headline1: {
            fontFamily: 'MontserratAlternates-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 38,
            lineHeight: 40,
        },
        headline2: {
            fontFamily: 'MontserratAlternates-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 24,
            lineHeight: 28,
        },
        subtitle: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 20,
            lineHeight: 24,
        },
        subtitleBold: {
            fontFamily: 'PT-RootUI-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 24,
        },
        body: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 24,
        },
        bodyBold: {
            fontFamily: 'PT-RootUI-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 24,
        },
        bodyWide: {
            fontFamily: "PTSans-Regular",
            fontSize: 18,
            lineHeight: 28,
        },
        buttonPrimary: {
            fontFamily: 'PT-RootUI-Bold',
            fontweight: '700',
            fontSize: 14,
            lineHeight: 16,
            textTransform: "uppercase",
            letterSpacing: 1,
            marginBottom: -3.5,
            marginTop: -0.5,
            paddingBottom: 2,
            alignSelf: 'stretch',
        },
        buttonSecondary: {
            fontFamily: "PTSansNarrow-Regular",
            fontSize: 16,
            lineHeight: 16,
        },
        overline: {
            fontFamily: "PTSans-Regular",
            fontSize: 16,
            lineHeight: 16,
            textTransform: "uppercase",
        },
        overlineBold: {
            fontFamily: "PTSans-Bold",
            fontSize: 16,
            lineHeight: 16,
            textTransform: "uppercase",
        },
        caption1: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            lineHeight: 20,
        },
        caption1Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 16,
            lineHeight: 20,
        },
        caption2: {
            fontFamily: 'PT-RootUI-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 16,
        },
        caption2Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 14,
            lineHeight: 16,
        },
        caption3: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            lineHeight: 24,
        },
        caption3Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 12,
            lineHeight: 16,
        },
    };
    return FontsEnglish;
}