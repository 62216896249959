const IMAGES = {
    fiveLogo: require('./public/FiveLogo.png'),
    nineLogo: require('./public/NineLogo.png'),
    fiveUmbrella: require('./public/FiveUmbrella.png'),
    nineUmbrella: require('./public/NineUmbrella.png'),
    fiveBottomBarImageSrc: require('./public/FiveBottomBackground.svg'),
    nineBottomBarImageSrc: require('./public/NineBottomBackground.svg'),
    fiveTopBarImageSrc: require('./public/FiveTopBackground.svg'),
    nineTopBarImageSrc: require('./public/NineTopBackground.svg'),
    iphoneHebrew: require('./public/IphoneHebrew.png'),
    iphoneEnglish: require('./public/IphoneEnglish.png'),
    suranceGreen: require('./public/Surance-pattern.svg'),
    suranceLogoCyberAid: require('./public/SuranceLogoCyberAid.svg'),
    cyberAidBackground: require('./public/CyberAidBackground.png'),
    helpCenter: require('./public/HelpCenter.png'),
    nineTab: require('./public/NineTab.png'),
    fiveTab: require('./public/FiveTab.png'),
    cyberaidTab: require('./public/SuranceTabLogo.png'),
}

export default IMAGES;