
import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import TopBar from './TopBar';
import Main from './Main';
import BottomBar from './BottomBar';
import IMAGES from '../../Images';
import { isExtraSmallScreen } from '../../utils';
import { useTheme } from '@suranceadmin/surance-ui-components';

const BituahNine = () => {
    const theme = useTheme();
    const [topBarLayout, setTopBarLayout] = useState(0);
    const { styles } = useStyle(topBarLayout, theme);
    const umbrellaImg = <Image style={styles.umbrella} source={IMAGES.nineUmbrella} />

    return (
        <View style={styles.container}>
            <TopBar setLayout={setTopBarLayout} />
            <Main />
            {umbrellaImg}
            <BottomBar />
        </View>
    )
}

const useStyle = (topBarLayout, theme) => {
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.colors.background.white
        },
        umbrella: {
            width: isExtraSmallScreen ? 115 : 164,
            height: isExtraSmallScreen ? 115 : 164,
            position: 'absolute',
            top: topBarLayout - (isExtraSmallScreen ? 92 : 130),
            left: 20,
            zIndex: 9
        },
    })
    return { styles };
}

export default BituahNine;