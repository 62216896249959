import React from 'react';
import { View, StyleSheet } from 'react-native';
import { mapThemes } from '../utils';
import { CYBERAID, IDI } from '../constants';
import { BituahYashir } from './idi';
import { CyberAid } from './CyberAid';

const Container = (desktop) => {
    const { styles } = useStyle(desktop);
    const EnvByProjectId = mapThemes({
        [IDI]: BituahYashir,
        [CYBERAID]: CyberAid,
    });

    return (
        <View style={styles.container}>
            <EnvByProjectId />
        </View>
    )
}

const useStyle = (desktop) => {
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'column',
            maxWidth: desktop && 500
        },
    });
    return { styles };
}


export default Container;

