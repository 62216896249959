
import React from 'react';
import { View, Linking, ImageBackground, Image } from 'react-native';
import { Text, TextType, Button, ButtonType, useTheme } from '@suranceadmin/surance-ui-components';
import IMAGES from '../../Images';
import { androidOrIOS, mapThemes } from '../../utils';
import { useAnalyticsEventTracker } from '../../Analytics';
import { analyticsLabels, languages, IDI, CYBERAID } from '../../constants';
import { useIntl } from 'react-intl';
import config from '../../config';

const getDownloadButtonText = () => {
    const intl = useIntl();
    const buttonTextMapping = mapThemes({
        [IDI]: intl.formatMessage({ id: 'downloadTheApp' }),
        [CYBERAID]: intl.formatMessage({ id: 'downloadNow' }),
    });
    return buttonTextMapping;
}

const openAppDownloadLink = (label) => {
    const deviceType = androidOrIOS();
    useAnalyticsEventTracker(label);
    if (deviceType === 'android') {
        Linking.openURL(config.androidAppLink);
    } else {
        Linking.openURL(config.iosAppLink);
    }
}

export const MainTopContainer = (props) => {
    const { styles, onLayout, title, subTitle, secondSubtitle } = props;
    return (
        <View style={styles.topContainer} onLayout={onLayout}>
            <Text type={TextType.Headline2} style={styles.title}>{title}</Text>
            <DownloadAppButton label={analyticsLabels.TOP} styles={styles} openAppDownloadLink={openAppDownloadLink} />
            <Text type={TextType.Subtitle} style={styles.subTitle}>{subTitle}</Text>
            {secondSubtitle && <Text type={TextType.Caption3} style={styles.discount}>{secondSubtitle}</Text>}

        </View>
    );
};

export const MainBottomContainer = (props) => {
    const { styles, bottomText, DescriptionBelowIphoneLogo, belowIphoneLogo } = props;
    const theme = useTheme();
    return (
        <ImageBackground source={IMAGES.suranceGreen} imageStyle={styles.imageBorderRadius} style={styles.image}>
            <View style={styles.bottomContainer}>
                <DescriptionBelowIphoneLogo styles={styles} belowIphoneLogo={belowIphoneLogo} />
                <Text type={TextType.SubtitleBold} style={[styles.subTitle, { color: theme.colors.text.link }]}>
                    {bottomText}
                </Text>
                <DownloadAppButton label={analyticsLabels.BOTTOM} styles={styles} openAppDownloadLink={openAppDownloadLink} bottom={true} />
            </View>
        </ImageBackground>
    )
}

export const IphoneLogo = ({ styles }) => {
    const iphoneHebrew = <Image style={styles.iphone} source={IMAGES.iphoneHebrew} />;
    const iphoneLogoMapping = {
        [languages.HEBREW]: iphoneHebrew,
        [languages.ENGLISH]: <Image style={styles.iphone} source={IMAGES.iphoneEnglish} />,
    };
    return iphoneLogoMapping[config.language] || iphoneHebrew;
}

export const DownloadAppButton = ({ label, styles, openAppDownloadLink, bottom = null }) => {
    const buttonText = getDownloadButtonText();
    return (
        <Button
            type={ButtonType.Primary}
            style={[styles.button, { marginBottom: bottom ? 40 : 10 }]}
            onPress={() => openAppDownloadLink(label)}>
            {buttonText}</Button>
    )
};