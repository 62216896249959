import React from 'react';
import { View, ImageBackground } from 'react-native';
import { Text, TextType } from '@suranceadmin/surance-ui-components';

const TopBar = (props) => {
    const { logo, backgroundImage, styles, title, subTitle, setLayout } = props;

    const onLayout = (event) => {
        if (setLayout) {
            const { height } = event.nativeEvent.layout;
            setLayout(height);
        }
    }

    return (
        <ImageBackground source={backgroundImage} style={styles.container} onLayout={onLayout}>
            <View style={styles.horizontalPadding}>
                <View style={styles.logoContainer}>
                    {logo}
                </View>
                <Text type={TextType.Headline1} style={styles.title}>{title}</Text>
                {subTitle &&
                    <Text type={TextType.Subtitle} style={styles.subTitle}>{subTitle}</Text>}
            </View>
        </ImageBackground>
    )
};

export default TopBar;