import React from 'react';
import { StyleSheet, Image } from 'react-native';
import { useTheme } from '@suranceadmin/surance-ui-components';
import { isSmallScreen, isExtraSmallScreen, isBituah9 } from '../../utils';
import TopBarCommon from '../common/TopBar';
import IMAGES from '../../Images';
import { useIntl } from 'react-intl';
import { NINE_BRAND_NAME, FIVE_BRAND_NAME } from '../../constants';

export const getTopBarText = () => {
    const intl = useIntl();
    const Bituah9 = isBituah9();
    const brand = Bituah9 === true ? NINE_BRAND_NAME : FIVE_BRAND_NAME;
    const topBarTextAndBackgroundImage = {
        title: intl.formatMessage({ id: 'ThankYou' }),
        subTitle: intl.formatMessage({ id: 'forPurchasingCyberCoverage' }, { brand }),
        backgroundImage: Bituah9 ? IMAGES.nineTopBarImageSrc : IMAGES.fiveTopBarImageSrc,
    };
    return topBarTextAndBackgroundImage;
}

export const getTopBarLogo = (styles) => {
    const Bituah9 = isBituah9();
    const nineLogo = <Image style={styles.nineLogo} source={IMAGES.nineLogo} />;
    const fiveLogo = <Image style={styles.fiveLogo} source={IMAGES.fiveLogo} />;
    const logo = Bituah9 ? nineLogo : fiveLogo;
    return logo;
}


const TopBar = ({ setLayout }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme);
    const logo = getTopBarLogo(styles);
    const { title, subTitle, backgroundImage } = getTopBarText();

    return (
        <TopBarCommon {...{ logo, backgroundImage, styles, title, subTitle, setLayout }} />
    )
};

const useStyle = (theme) => {
    const styles = StyleSheet.create({
        fiveLogo: {
            width: 135,
            height: 42,
            marginTop: isExtraSmallScreen ? 18 : 36
        },
        nineLogo: {
            width: 59,
            height: 80,
            marginTop: isExtraSmallScreen ? 13 : 26
        },
        umbrella: {
            width: 164,
            height: 164,
            position: 'absolute',
            left: 20,
            bottom: -50,
            zIndex: 1
        },
        container: {
            minHeight: isExtraSmallScreen ? 280 : isSmallScreen ? 350 : 430,
            writingDirection: 'auto',
            position: 'relative',
        },
        horizontalPadding: {
            paddingHorizontal: 24,
        },
        title: {
            marginTop: isExtraSmallScreen ? 15 : isSmallScreen ? 33 : 66.5,
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.text.ondark,
        },
        subTitle: {
            color: theme.colors.text.ondark,
            textAlign: theme.isRTL ? 'right' : 'left',
        },
        logoContainer: {
            alignItems: theme.isRTL ? 'flex-end' : 'flex-start',
        },
    });
    return { styles };
};

export default TopBar;