export const urls = {
    FIVE: 'www.555.co.il',
    NINE: 'www.9000000.co.il',
};

export const analyticsLabels = {
    TOP: 'Top',
    BOTTOM: 'Bottom',
    HELP_CENTER: 'Help Center',
    TERMS_OF_USE: 'Terms of Use',
    PRIVACY_POLICY: 'Privacy Policy',
};

export const BITUAH_NINE = 'Bituah9';
export const BITUAH_FIVE = 'Bituah5';

export const SUPPORT_URL = 'https://support.cyberaid.app';

export const IDI = 'idi-il-prod';
export const CYBERAID = 'cyberaid-us-prod';

export const NINE_BRAND_NAME = '9';
export const FIVE_BRAND_NAME = 'ישיר';

export const NINE_PHONE_NUMBER = '03-9000000';
export const FIVE_PHONE_NUMBER = '03-5555555';

export const TERMS_OF_USE = 'https://www.surance.io/terms-of-use';
export const PRIVACY_POLICY = 'https://surance.io/privacy-statement';

export const languages = {
    HEBREW: 'he',
    ENGLISH: 'en',
};