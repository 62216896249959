import { Dimensions } from 'react-native';
import { IDI, CYBERAID } from './constants';
import config from './config';
import IMAGES from './Images';


export function mapThemes(themeMapping, defaultTheme = IDI) {
    const projectId = getProjectId();
    const element =
        typeof themeMapping[projectId] === 'undefined'
            ? themeMapping[defaultTheme]
            : themeMapping[projectId];
    return element;
}

export const getProjectId = () => {
    const projectId = config.projectId;
    return projectId;
};


export function androidOrIOS() {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        return 'android';
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'ios';
    }
}

export const getTabNameAndIcon = () => {
    const Bituah9 = isBituah9();
    const nameAndIconMapping = mapThemes({
        [IDI]: {
            name: 'ביטוח ישיר',
            icon: Bituah9 ? IMAGES.nineTab : IMAGES.fiveTab
        },
        [CYBERAID]: {
            name: 'CyberAid',
            icon: IMAGES.cyberaidTab
        }
    });
    return nameAndIconMapping;
}


export const setTabNameAndIcon = () => {
    const { name, icon } = getTabNameAndIcon();
    document.title = name;
    const link = document.createElement('link');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.rel = 'apple-touch-icon';
    link.href = icon;
    document.head.appendChild(link);
}

export function isProduction() {
    const prodHost = config.prodHost;
    if (document.location.hostname.indexOf(prodHost) != -1) {
        return true;
    } else {
        return false;
    }
}

export const isBituah9 = () => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const parameterChatId = urlParams.get("brand");
    return parameterChatId === '9';
}

const windowHeight = window.screen.height
const windowWidth = Dimensions.get('window').width;
const isSmallScreen = windowWidth <= 320 || windowHeight <= 812;
const isExtraSmallScreen = windowHeight <= 667;

export { isSmallScreen, isExtraSmallScreen };

