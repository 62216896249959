import ReactGA from "react-ga4";
import { getProjectId, isBituah9, isProduction } from "./utils";
import { BITUAH_NINE, BITUAH_FIVE, IDI, CYBERAID } from "./constants";
import config from './config';

const TRACKING_ID = config.analyticsId;

export const initializeAnalytics = () => {
    const production = isProduction();
    if (production) {
        ReactGA.initialize(TRACKING_ID);
    }
}

export const useAnalyticsEventTracker = (label) => {
    const production = isProduction();
    if (production) {
        const action = "Button clicked";
        const category = getCategroyByProjectID();
        ReactGA.event({ category, action, label });
    }
}

const getCategroyByProjectID = () => {
    const projectId = getProjectId();
    const Bituah9 = isBituah9();
    const categoryMapping = {
        [IDI]: Bituah9 ? BITUAH_NINE : BITUAH_FIVE,
        [CYBERAID]: CYBERAID,
    }
    return categoryMapping[projectId];
}