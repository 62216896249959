import React from 'react';
import { StyleSheet, View } from 'react-native';
import TopBar from './TopBar';
import Main from './Main';
import BottomBar from './BottomBar';

export const CyberAid = () => {
    const { styles } = useStyle();
    return (
        <View style={styles.container}>
            <TopBar />
            <Main />
            <BottomBar />
        </View>
    )
}

export default CyberAid;

const useStyle = () => {
    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
    });
    return { styles };
}
