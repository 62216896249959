import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, TextType, useTheme } from '@suranceadmin/surance-ui-components';
import { isBituah9, isExtraSmallScreen } from '../../utils';
import { useIntl } from 'react-intl';
import { IphoneLogo, MainTopContainer, MainBottomContainer } from '../common/Main';

export const getMainText = () => {
    const intl = useIntl();
    const mainText = {
        title: intl.formatMessage({ id: 'downloadBituahYashirApp' }),
        subTitle: intl.formatMessage({ id: 'assitsToProvideCyberAttack' }),
        secondSubtitle: intl.formatMessage({ id: 'appInstallDiscount' }),
        belowIphoneLogo: intl.formatMessage({ id: 'downloadAndLearnHowToProtect' }),
    };
    return mainText;
}

export const DescriptionBelowIphoneLogo = ({ styles, belowIphoneLogo }) => {
    const theme = useTheme();
    return (
        <Text type={TextType.Body} style={[styles.subTitle, { color: theme.colors.text.ondark }]}>
            {belowIphoneLogo}
        </Text>
    )
}

const Main = () => {
    const theme = useTheme();
    const [layout, setLayout] = useState(0);
    const { styles } = useStyle(theme, layout);
    const { title, subTitle, secondSubtitle, belowIphoneLogo } = getMainText();
    const onLayout = (event) => {
        const { height } = event.nativeEvent.layout;
        setLayout(height);
    }

    return (
        <View style={{ zIndex: 1, position: 'relative' }}>
            <MainTopContainer {...{ styles, onLayout, title, subTitle, secondSubtitle, belowIphoneLogo }} />
            <MainBottomContainer {...{ styles, belowIphoneLogo, DescriptionBelowIphoneLogo }} />
            <IphoneLogo styles={styles} />
        </View>
    )
}




const useStyle = (theme, layout) => {
    const Bituah9 = isBituah9();
    const styles = StyleSheet.create({
        topContainer: {
            paddingBottom: 100,
            backgroundColor: theme.colors.background.default,
            writingDirection: 'auto',
            paddingHorizontal: 24,
            borderTopLeftRadius: Bituah9 ? 0 : 24,
            borderTopRightRadius: Bituah9 ? 0 : 24,
            borderWidth: 1,
            borderColor: theme.colors.background.default,
            position: 'relative',
            zIndex: 2,
            flex: 1
        },
        bottomContainer: {
            paddingLeft: 22,
            paddingRight: 22,
            writingDirection: 'auto',
        },
        title: {
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.background.header,
            marginTop: isExtraSmallScreen ? 28 : 64,
            writingDirection: 'auto',
        },
        subTitle: {
            marginTop: 8,
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.background.header,
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        button: {
            marginTop: 20,
            height: 48,
        },
        image: {
            justifyContent: "center",
            paddingTop: 230,
        },
        imageBorderRadius: {
            borderBottomLeftRadius: Bituah9 ? 0 : 24,
            borderBottomRightRadius: Bituah9 ? 0 : 24
        },
        iphone: {
            width: 171,
            height: 360,
            position: 'absolute',
            top: layout - 100,
            zIndex: 8,
            alignSelf: 'center',
        },
        discount: {
            color: 'rgb(1 34 48)',
            fontWeight: 800,
            writingDirection: 'auto',
            textAlign: theme.isRTL ? 'right' : 'left',
            marginTop: 8,
            marginBottom: 10
        },
    });
    return { styles };
}


export default Main;