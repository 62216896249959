export const getFontsHebrewSmall = () => {
    const FontsHebrewSmall = {
        headline1: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 38,
            lineHeight: 44,
        },
        headline2: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 27,
            lineHeight: 30,
        },
        subtitle: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 20,
        },
        subtitleBold: {
            fontFamily: 'Montserrat-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 12,
            lineHeight: 20,
        },
        body: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 18,
            lineHeight: 26,
        },
        bodyBold: {
            fontFamily: 'Montserrat-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 18,
            lineHeight: 18,
        },
        bodyWide: {
            fontFamily: "PTSans-Regular",
            fontSize: 14,
            lineHeight: 21,
        },
        buttonPrimary: {
            fontFamily: 'Arial-Bold',
            fontweight: '700',
            fontSize: 20,
            lineHeight: 16,
            textTransform: "uppercase",
            letterSpacing: 1,
            marginBottom: -3.5,
            marginTop: -0.5,
            paddingBottom: 2,
            alignSelf: 'stretch',
        },
        buttonSecondary: {
            fontFamily: "PTSansNarrow-Regular",
            fontSize: 12,
            lineHeight: 12,
        },
        overline: {
            fontFamily: "PTSans-Regular",
            fontSize: 12,
            lineHeight: 12,
            textTransform: "uppercase",
        },
        overlineBold: {
            fontFamily: "PTSans-Bold",
            fontSize: 12,
            lineHeight: 12,
            textTransform: "uppercase",
        },
        caption1: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 23,
        },
        caption1Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 12,
            lineHeight: 15,
        },
        caption2: {
            fontFamily: 'PTSans-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 18,
        },
        caption2Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 14,
            lineHeight: 16,
        },
        caption3: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 18,
        },
        caption3Bold: {
            fontFamily: "PTSans-Bold",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 9,
            lineHeight: 12,
        },
    };
    return FontsHebrewSmall;
}

export const getFontsHebrew = () => {
    const FontsHebrew = {
        headline1: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 56,
            lineHeight: 66,
        },
        headline2: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 36,
            lineHeight: 40,
        },
        subtitle: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 20,
            lineHeight: 30,
        },
        subtitleBold: {
            fontFamily: 'Montserrat-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 30,
        },
        body: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 20,
            lineHeight: 33,
        },
        bodyBold: {
            fontFamily: 'Montserrat-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 24,
            lineHeight: 24,
        },
        bodyWide: {
            fontFamily: "PTSans-Regular",
            fontSize: 18,
            lineHeight: 28,
        },
        buttonPrimary: {
            fontFamily: 'Arial-Bold',
            fontweight: '700',
            fontSize: 20,
            lineHeight: 16,
            textTransform: "uppercase",
            letterSpacing: 1,
            marginBottom: -3.5,
            marginTop: -0.5,
            paddingBottom: 2,
            alignSelf: 'stretch',
        },
        buttonSecondary: {
            fontFamily: "PTSansNarrow-Regular",
            fontSize: 16,
            lineHeight: 16,
        },
        overline: {
            fontFamily: "PTSans-Regular",
            fontSize: 16,
            lineHeight: 16,
            textTransform: "uppercase",
        },
        overlineBold: {
            fontFamily: "PTSans-Bold",
            fontSize: 16,
            lineHeight: 16,
            textTransform: "uppercase",
        },
        caption1: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 20,
            lineHeight: 30,
        },
        caption1Bold: {
            fontFamily: "PTSans-Bold",
            fontSize: 16,
            lineHeight: 20,
        },
        caption2: {
            fontFamily: 'PTSans-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 24,
        },
        caption2Bold: {
            fontFamily: "PTSans-Bold",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 14,
            lineHeight: 16,
        },
        caption3: {
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            lineHeight: 24,
        },
        caption3Bold: {
            fontFamily: "PTSans-Bold",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 12,
            lineHeight: 16,
        },
    };
    return FontsHebrew;
}