import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/he";
import { I18nManager } from "react-native";
import React, { useContext, createContext } from "react";
import { useIntl as useReactIntl, IntlProvider } from "react-intl";
import { getParsedTranslations } from "./utils";
import en from "./translations/en";
import he from "./translations/he";
import { languages } from "../constants";

const translations = getParsedTranslations({ en, he });

export const checkRtlState = (locale) => {
    const isRtl = locale === languages.HEBREW;
    if (isRtl !== I18nManager.isRTL) {
        I18nManager.allowRTL(isRtl);
        I18nManager.forceRTL(isRtl);
    }
};

const IntlProviderRtl = ({ locale, ...props }) => {
    if (!translations[locale]) {
        locale = languages.ENGLISH;
    }
    const transl = translations[locale];

    return (
        <IntlProvider locale={locale} messages={transl}>
            {props.children}
        </IntlProvider>
    );
};

const KeyContextContext = createContext();

export const useIntl = () => {
    const context = useContext(KeyContextContext);
    const intl = useReactIntl();
    if (context === undefined) {
        throw new Error("useIntl must be used within UITextKeyProvider");
    }

    return { ...context, ...intl };
};

export default {
    IntlProvider: IntlProviderRtl,
    translations,
    currentLocale: process.env.LANGUAGE || languages.ENGLISH,
};
